<template>
  <div>
    <div v-if="!isMySelf" class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <h3>{{ $t('members.member-detail.connections') }}</h3>
        <h5><b-icon-shield-check v-b-tooltip.hover.bottom size="2" :title="$t('members.member-detail.shield-tooltip')" class="mt-50 ml-25" /></h5>
      </div>
      <div class="d-flex align-items-center">
        <p v-if="memberRelation && total" class="dark-text text-center m-0" :class="isStaff && 'mr-2 text-primary cursor-pointer'" @click="openConnectionModal">
          {{ total }} {{ $t('members.member-detail.connections') }}
        </p>

        <b-button
          v-if="isStaff"
          class="text-primary p-0 d-flex justify-content-end create-item"
          variant="link"
          @click="openConnectionModal"
        >
          <feather-icon icon="PlusIcon" variant="primary" size="20" />
        </b-button>
      </div>
    </div>
    <b-spinner
      v-if="isLoading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <b-row v-else-if="memberRelation.length > 0 && !isLoading">
      <b-col
        v-for="collaborator in memberRelation"
        :key="collaborator.key"
        cols="3"
        class="mt-1"
      >
        <b-link :to="getMemberLocation(collaborator)">
          <div class="d-flex justify-content-center">
            <user-avatar
              v-if="collaborator"
              :user="collaborator"
              size="46"
              class="mr-50 mb-25"
              style="background-color: lightgrey;"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p v-if="collaborator" class="small m-0 text-center text-ellipsis-lines-2">
              {{ collaborator.name }} {{ collaborator.surname }}
            </p>
          </div>
        </b-link>
        <b-button v-if="isMySelf && isEditing" variant="outline-primary" class="d-block mx-auto p-25 p-lg-50 mt-lg-n1" @click="handleDeleteItem(collaborator)"><small>{{$t('members.desconnection')}}</small></b-button>
      </b-col>
      <div
        v-if="memberRelation && total && memberRelation.length < total"
        class="w-100 mt-2 mb-2 d-flex justify-content-center"
      >
        <b-button variant="link" @click="handleLoadOfNewItems">
          {{ $t('members.show-more') }}
        </b-button>
      </div>
    </b-row>
    <!-- Container without data: Placeholder -->
    <div v-else class="horizontal-placeholder d-flex flex-column h-100 media-placeholder">
      <img :src="placeholderCollaborator">

      <p class="text-primary">
        {{ $t('available.message', { itemName: $t('members.member-detail.connections') }) }}
      </p>
    </div>
    <!-- MODAL PARA AÑADIR MIEMBROS -->
    <b-modal
      v-if="isStaff && isConnectionModalVisible"
      id="modal-create-jobs"
      v-model="isConnectionModalVisible"
      :title="$t('members.relation-modal')"
      centered
      size="lg"
      hide-footer
      @change="closeModal()"
    >
      <member-add-connections-modal :selected-member="member" @addConnection="closeModal()" @restConnection="closeModal()"/>
    </b-modal>
  </div>
</template>

<script>

import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import UserAvatar from '@/@core/components/user/UserAvatar.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import MemberAddConnectionsModal from '../modals/MemberAddConnectionsModal.vue';
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'MemberConnections',
  components: {
    UserAvatar,
    WidgetActions,
    MemberAddConnectionsModal,
  },
  props: {
    isEditing: Boolean,
    member: {
      type: Object,
      required: true,
    },
    isStaff: {
      type: Boolean,
      required: true,
    },
    isMySelf: {
      type: Boolean,
      required: true,
    },
    wantConnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isConnectionModalVisible: false,
      lastLoadedPage: 1,
      isLoading: false,
    };
  },
  computed: {
    placeholderCollaborator() {
      return MembersPlaceholder;
    },
    memberRelation() {
      return this.$store.getters.memberRelation.unpaginated;
    },
    total() {
      return this.$store.getters.memberRelation?.meta?.total;
    },
  },
  watch: {
    wantConnection(value) {
      if (value === true) {
        this.openConnectionModal();
      }
    },
  },
  async created() {
    await this.fetchDataFriends(this.member.key);
    this.$emit('relations', this.memberRelation);
    this.$emit('total', this.total);
  },
  methods: {
    async fetchDataFriends(memberKey, forceAPICall = true ) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'memberRelation',
        page: this.lastLoadedPage,
        forceAPICall,
        perPage: 16,
        requestConfig: { orderByDate: -1, memberKey },
      });
      this.isLoading = false;
    },
    async handleLoadOfNewItems() {
      if (this.memberRelation.length < this.total) {
        this.lastLoadedPage += 1;
        await this.fetchDataFriends(this.member.key, false);
      }
    },

    getMemberLocation(user) {
      if (!user.name) {
        return null;
      }
      return {
        name: 'member-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },
    async openConnectionModal() {
      this.isConnectionModalVisible = true;
    },
    async closeModal() {
      this.isConnectionModalVisible = false;
      await this.fetchDataFriends(this.member.key);
      this.$emit('relations', this.memberRelation);
      this.$emit('wantConnections', false);
      this.$emit('total', this.total);
    },
    async handleDeleteItem(collaborator) {
      try {
        await this.$store.dispatch('deleteItem', {
          item: {
            itemType: 'myMemberRelationships',
            requestConfig: {
              memberKeys: [this.member.key, collaborator.key],
            },
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('members.desconnection-alert'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.hasRelation = false;
        await this.fetchDataFriends(this.member.key);
        this.$emit('total', this.total);

      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>

<style></style>
